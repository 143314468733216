import Image1 from '../containers/NewsListPage/img/Image1.png';
import Image2 from '../containers/NewsListPage/img/Image2.png';
import Image3 from '../containers/NewsListPage/img/Image3.png';
import Image4 from '../containers/NewsListPage/img/Image4.png';
import Image5 from '../containers/NewsListPage/img/Image5.png';
import Image6 from '../containers/NewsListPage/img/Image6.png';
import Image7 from '../containers/NewsListPage/img/Image7.png';
import Image8 from '../containers/NewsListPage/img/Image8.png';
import Image9 from '../containers/NewsListPage/img/Image9.png';
import Image10 from '../containers/NewsListPage/img/Image10.png';
import Image11 from '../containers/NewsListPage/img/Image11.png';
import Image12 from '../containers/NewsListPage/img/Image12.png';
import Image13 from '../containers/NewsListPage/img/Image13.png';
import Image49 from '../containers/NewsListPage/img/Image49.png';
import ImageNews2 from '../containers/NewsListPage/img/news2.png';

import logoBloomberg from '../containers/NewsListPage/img/logoBloomberg.png';
import logoBusinessInsider from '../containers/NewsListPage/img/logoBusinessInsider.png';
import logoDistrictAdministration from '../containers/NewsListPage/img/logoDistrictAdministration.png';
import logoForbes from '../containers/NewsListPage/img/logoForbes.png';
import logoNewsweek from '../containers/NewsListPage/img/logoNewsweek.png';
import logoNewYorkTimes from '../containers/NewsListPage/img/logoNewYorkTimes.png';
import logoYahooFinance from '../containers/NewsListPage/img/logoYahooFinance.png';


export const newsData = [
  {description: 'This Startup Pushes Technology Into Schools With Paper and Pen', title: 'Bloomberg', link: 'https://www.bloomberg.com/news/articles/2020-09-18/this-startup-pushes-technology-into-schools-with-paper-and-pen?leadSource=uverify%20wall', imgSrc: Image49, pageLink: 'NewsListPage', logo: logoBloomberg},
  {description: 'What If You Could Learn Everything?', title: 'Newsweek', link: 'https://www.newsweek.com/2013/07/10/what-if-you-could-learn-everything-237660.html', imgSrc: Image6, pageLink: 'NewsListPage', logo: logoNewsweek},
  {description: 'World\'s Greatest Tutor', title: 'Forbes', link: 'https://www.forbes.com/forbes/2012/0312/technology-jose-ferreira-knewton-learn-eduction-world-greatest-tutor.html?sh=16cb03021d02', imgSrc: Image12, pageLink: 'NewsListPage', logo: logoForbes},
  {description: 'The Machines Are Learning, and So Are the Students', title: 'The New York Times', link: 'https://www.nytimes.com/2019/12/18/education/artificial-intelligence-tutors-teachers.html', imgSrc: Image4, pageLink: 'NewsListPage', logo: logoNewYorkTimes},
  {description: 'District Administration', title: 'District Administration', imgSrc: ImageNews2, pageLink: 'NewsListPage', page: 'LandingPage', logo: logoDistrictAdministration},
  {description: 'Tech companies changing face of education', title: 'The Today Show', link: 'https://www.youtube.com/watch?v=d4UQFgAdxUs', imgSrc: Image1, pageLink: 'NewsListPage', },
  {description: 'The Adaptive Learning Revolution', title: 'Time Magazine', link: 'https://nation.time.com/2013/06/06/the-adaptive-learning-revolution/', imgSrc: Image2, pageLink: 'NewsListPage'},
  {description: 'Jose Ferreira: Students, Learning Paths, and Technology', title: 'Stanford University', link: 'https://www.youtube.com/watch?v=-EXHUpCyEOA', imgSrc: Image3, pageLink: 'NewsListPage'},
  {description: 'Most Innovative Companies 2012', title: 'Fast Company', link: 'https://www.fastcompany.com/most-innovative-companies/2012/full-list', imgSrc: Image5, pageLink: 'NewsListPage'},
  {description: 'Jose Ferreira of Bakpax: “How To Develop Serenity During Anxious Times”', title: 'Authority Magazine', link: 'https://medium.com/authority-magazine/jose-ferreira-of-bakpax-how-to-develop-serenity-during-anxious-times-5e3ee92c821b', imgSrc: Image7, pageLink: 'NewsListPage'},
  {description: 'Is the Google-fication of education underway?', title: 'Fortune', link: 'https://fortune.com/2011/01/07/is-the-google-fication-of-education-underway/', imgSrc: Image8, pageLink: 'NewsListPage'},
  {description: 'America\'s education system needs a pandemic bailout', title: 'Business Insider', link: 'https://www.businessinsider.com/relief-bill-education-schools-colleges-banks-wall-st-bailout-2020-6', imgSrc: Image13, pageLink: 'NewsListPage', logo: logoBusinessInsider},
  {description: 'This Startup Pushes Technology Into Schools With Paper and Pen', title: 'Yahoo Finance', link: 'https://finance.yahoo.com/news/startup-pushes-technology-schools-paper-090000278.html?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAFN6dAtYEDydropW_PHimfc2OUV5rjnZQxsO4aJJfJwk3umBnz3mnY6qZP-oitikoXX91w1i7Z_-SmwKaLRbV6F15hldwL9juGjaza2_Kfdcz7CBfhpwqu2O0LuxC29NyLsSt-RZNC2e7VD9zTTWL_evGFaAZIVVnrDv0mbqw_JN&guccounter=2', imgSrc: Image9, pageLink: 'NewsListPage', logo: logoYahooFinance},
  {description: 'Straight From Davos: There\'s A Reason It\'s Called \'Venture\' Capital\n', title: 'Business Insider', link: 'https://www.businessinsider.com/straight-from-davos-theres-a-reason-its-called-venture-capital-2012-1', imgSrc: Image10, pageLink: 'NewsListPage'},
  {description: 'Straight From Davos: There\'s A Reason It\'s Called \'Venture\' Capital\n', title: 'The Economist', link: 'https://www.economist.com/babbage/2010/09/01/questions-for-jose-ferreira', imgSrc: Image11, pageLink: 'NewsListPage'},
]
